import { ReactNode } from 'react';
import { FilterPickerType } from '../FilterPicker';
import { SelectProps } from '@prio365/prio365-react-library/lib/Select';
import OfficeFilterPickerConsumer from './OfficeFilterPickerConsumer';
import { GlobalRole, OfficeRole, ProjectRole } from '../../../models/Types';
import ProjectFilterPickerConsumer from './ProjectFilterPickerConsumer';
import ContactFilterPickerConsumer from './ContactFilterPickerConsumer';
import CompanyFilterPickerConsumer from './CompanyFilterPickerConsumer';
import EmployeeFilterPickerConsumer from './EmployeeFilterPickerConsumer';
import DocumentTemplateCategoryFilterPickerConsumer from './DocumentTemplateCategoryFilterPickerConsumer';
import ContactTagFilterPickerConsumer from './ContactTagFilterPickerConsumer';
import DocumentTemplateTagFilterPickerConsumer from './DocumentTemplateTagFilterPickerConsumer';
import FileExtensionPickerConsumer from './FileExtensionPickerConsumer';
import DocumentTagPickerConsumer from './DocumentTagsPickerConsumer';

export declare type FilterPickerChildrenFunction = (
  props: Partial<Omit<SelectProps, 'options'>> & {
    options: {
      label: ReactNode;
      value: string;
      disabled?: boolean;
      [name: string]: any;
    }[];
  }
) => ReactNode;

export interface FilterPickerRolesMap {
  projectRoles: ProjectRole[];
  officeRoles: OfficeRole[];
  globalRoles: GlobalRole[];
}

export interface FilterPickerConsumerProps {
  children: FilterPickerChildrenFunction;
  roles: FilterPickerRolesMap;
  onChange: (value: string[], option) => void;
  value: string[];
}

interface FilterPickerDynamicConsumerProps extends FilterPickerConsumerProps {
  type: FilterPickerType;
}

const FilterPickerDynamicConsumer: React.FC<
  FilterPickerDynamicConsumerProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { type, ...rest } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  switch (type) {
    case 'contact': {
      return <ContactFilterPickerConsumer {...rest} />;
    }
    case 'internalContact': {
      return <ContactFilterPickerConsumer {...rest} type="internal" />;
    }
    case 'externalContact': {
      return <ContactFilterPickerConsumer {...rest} type="external" />;
    }
    case 'company': {
      return <CompanyFilterPickerConsumer {...rest} />;
    }
    case 'internalCompany': {
      return <CompanyFilterPickerConsumer {...rest} type="internal" />;
    }
    case 'externalCompany': {
      return <CompanyFilterPickerConsumer {...rest} type="external" />;
    }
    case 'project': {
      return <ProjectFilterPickerConsumer {...rest} />;
    }
    case 'office': {
      return <OfficeFilterPickerConsumer {...rest} />;
    }
    case 'internalOffice': {
      return <OfficeFilterPickerConsumer {...rest} type="internal" />;
    }
    case 'externalOffice': {
      return <OfficeFilterPickerConsumer {...rest} type="external" />;
    }
    case 'employee': {
      return <EmployeeFilterPickerConsumer {...rest} />;
    }
    case 'documentTemplateCategory': {
      return <DocumentTemplateCategoryFilterPickerConsumer {...rest} />;
    }
    case 'contactTag': {
      return <ContactTagFilterPickerConsumer {...rest} />;
    }
    case 'documentTemplateTag': {
      return <DocumentTemplateTagFilterPickerConsumer {...rest} />;
    }
    case 'fileExtension': {
      return <FileExtensionPickerConsumer {...rest} />;
    }
    case 'documentTag': {
      return <DocumentTagPickerConsumer {...rest} />;
    }
    default: {
      return null;
    }
  }
};

export default FilterPickerDynamicConsumer;
