import React, { useMemo, useState } from 'react';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@prio365/prio365-react-library';
import { MessageId, ProjectId } from '../../../../models/Types';
import {
  getActiveDraftMessageId,
  getActiveMessageId,
  getMessagesById,
  getProject,
  getUserMe,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { useSelector } from 'react-redux';
import NavigationBar from '../../../../components/NavigationBar';
import { MailCategoryButton } from './MailCategoryButton';
import { Project } from '../../../../models/Project';
import DeleteMailsButton from './DeleteMailsButton';
import { Message } from '../../../../models/Message';
import { createSelector } from 'reselect';
import { ByMessageId } from '../../actions/types';
import useDebounce from '../../../../hooks/useDebounce';
import * as Sentry from '@sentry/react';
import MailFlagButton from './MailFlagButton';
import MailMarkReadButton from './MailMarkReadButton';
import AssignMailsButton from './AssignMailsButton';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const activeMessageSelector = (projectId: ProjectId) =>
  createSelector<
    [
      (state: RootReducerState) => ByMessageId,
      (state: RootReducerState) => MessageId,
    ],
    Message
  >(
    (state) => getMessagesById(state, projectId),
    (state) => getActiveMessageId(state, projectId),
    (byId, activeMessage) => (activeMessage ? byId[activeMessage] : null)
  );

interface MailNavigationBarProps {
  projectId?: ProjectId;
  doShowNewMessage: (setIsCreating: (value: boolean) => void) => Promise<void>;
  selectedMessages: Message[];
  clearSelection: VoidFunction;
  isFavoriteView?: boolean;
}

export const MailNavigationBar: React.FC<MailNavigationBarProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    projectId,
    doShowNewMessage,
    selectedMessages: _selectedMessages,
    clearSelection,
    isFavoriteView,
  } = props;

  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );

  const activeMessage = useSelector(activeMessageSelector(projectId));
  const debouncedActiveMessage = useDebounce(activeMessage, 250);

  const activeDraftMessageId = useSelector<RootReducerState, MessageId>(
    (state) => getActiveDraftMessageId(state, projectId)
  );

  const userMe = useSelector(getUserMe);

  const [isCreating, setIsCreating] = useState<boolean>(false);

  const selectedMessages: Message[] = useMemo(() => {
    if (_selectedMessages.length > 0) {
      return _selectedMessages;
    }
    if (debouncedActiveMessage) {
      if (isFavoriteView) {
        return [{ ...debouncedActiveMessage, projectId }];
      }
      return [debouncedActiveMessage];
    }
    return [];
  }, [_selectedMessages, debouncedActiveMessage, projectId, isFavoriteView]);
  //#endregion

  return (
    <NavigationBar>
      <Button
        onClick={() => doShowNewMessage(setIsCreating)}
        disabled={isCreating || isFavoriteView}
        loading={isCreating}
        iconProp={['fal', 'edit']}
      >
        <span>{t('mail:navigationBar.newMessage')}</span>
      </Button>
      {((debouncedActiveMessage && !activeDraftMessageId) ||
        selectedMessages?.length > 0) && (
        <Routes>
          <Route
            path=":mailFolderId/*"
            element={
              <>
                <DeleteMailsButton
                  projectId={projectId}
                  selectedMessages={selectedMessages}
                  onClearSelection={clearSelection}
                />

                {!isFavoriteView && (
                  <MailCategoryButton
                    projectId={projectId}
                    selectedMessages={selectedMessages}
                  />
                )}
                {projectId !== 'me' && !isFavoriteView && (
                  <AssignMailsButton
                    projectId={projectId}
                    selectedMessages={selectedMessages}
                  />
                )}

                <MailMarkReadButton
                  projectId={projectId}
                  isFavoriteView={isFavoriteView}
                  selectedMessages={selectedMessages}
                />

                <MailFlagButton
                  projectId={projectId}
                  selectedMessages={selectedMessages}
                  isFavoriteView={isFavoriteView}
                />
              </>
            }
          />
        </Routes>
      )}
      {project ? (
        <Button
          type="default"
          href={`https://outlook.office.com/mail/${project.eMailPrefix}@${project.eMailSuffix}/inbox`}
          style={{ fontSize: '50' }}
        >
          {t('mail:navigationBar.openOfficeMail')}
        </Button>
      ) : projectId === 'me' && userMe ? (
        <Button
          type="default"
          href={`https://outlook.office.com/mail/${userMe.mail}/inbox`}
          style={{ fontSize: '50' }}
        >
          {t('mail:navigationBar.openOfficeMail')}
        </Button>
      ) : null}
    </NavigationBar>
  );
};

export default MailNavigationBar;
