import { useTranslation } from 'react-i18next';
import {
  ExternalProjectContact,
  InternalProjectContact,
  ProjectMemberSearchResultItem,
} from '../../../models/ProjectContacts';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootReducerState,
  getAllHourlyRates,
  getContactsByIdState,
  getUserMe,
} from '../../../apps/main/rootReducer';
import { HourlyRate } from '../../../models/HourlyRate';
import { ProjectRole } from '../../../models/Types';
import { ReactNode, useEffect, useState } from 'react';
import {
  updateInternalProjectContact,
  updateProjectContacts,
} from '../../projects/actions';
import {
  Button,
  Divider,
  Drawer,
  Select,
} from '@prio365/prio365-react-library';
import { Form, Switch } from 'antd';
import UserAvatar from '../../../components/UserAvatar';
import useCompaniesContext from '../../companies/hooks/useCompaniesContext';
import {
  ProjectExtension,
  ProjectExtensionAccess,
} from '../../../models/ProjectExtension';
import {
  apiFetchProjectExtensionsForProject,
  apiUpdateProjectExtensionAccesses,
} from '../../projects/api';
import SvgIcon from '../../../components/SvgIcon';
import { getSvgIconPathByType } from '../../../util/icon';
import TextArea from 'antd/lib/input/TextArea';
import JobTitleSelect from './JobTitleSelect';

interface ProjectContactsDrawerProps {
  visible: boolean;
  projectId: string;
  projectMember: ProjectMemberSearchResultItem;
  setOpenDrawer: (open: boolean) => void;
  onRefresh: () => void;
}

interface initialValues {
  jobTitle: string;
  roles: string[];
  hourlyRate: string;
  notes?: string;
}

const ProjectContactsDrawer: React.FC<ProjectContactsDrawerProps> = (props) => {
  //#region ------------------------------ Defaults
  const { visible, projectId, projectMember, setOpenDrawer, onRefresh } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [initialValues, setInitialValues] = useState<initialValues | null>(
    null
  );

  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [editTabkey, setEditTabKey] = useState<string>('general');
  const [loading, setLoading] = useState<boolean>(false);

  const [projectExtensions, setProjectExtensions] = useState<
    ProjectExtension[]
  >([]);

  const [projectExtensionAccesses, setProjectExtensionAccesses] = useState<
    ProjectExtensionAccess[]
  >([]);

  const hourlyRates = useSelector<RootReducerState, HourlyRate[]>((state) =>
    getAllHourlyRates(state, projectId)
  );

  const userMe = useSelector(getUserMe);
  const myProjectRoles: ProjectRole[] =
    userMe?.prioData.projectRoles[projectId];

  const contactsById = useSelector(getContactsByIdState);

  const { getCompanyById } = useCompaniesContext();

  const pojectRoles: ProjectRole[] = [
    'projectAdmin',
    'projectAssistance',
    'projectController',
    'projectMember',
  ];

  const hourlyRateOptions = hourlyRates?.map((i) => ({
    label: i.name,
    value: i.hourlyRateId,
  }));

  const roleOptions = pojectRoles?.map((i) => ({
    label: t(`contacts:projectContactsPage.projectRoles.${i}`),
    value: i,
    disabled: i === 'projectMember' ? true : false,
  }));

  //#endregion

  //#region ------------------------------ Effects

  useEffect(() => {
    if (visible && projectMember) {
      const initialHourlyRate = hourlyRates.find(
        (rate) => rate.hourlyRateId === projectMember?.data?.hourlyRateId
      )
        ? projectMember?.data?.hourlyRateId
        : '';
      const initialValues: initialValues = {
        jobTitle: projectMember.data.jobTitle,
        roles: projectMember.data.projectRoles,
        hourlyRate: initialHourlyRate,
        notes: projectMember.data.notes,
      };
      setInitialValues(initialValues);
      form.setFieldsValue(initialValues);
      setIsChanged(false);
    }
  }, [visible, projectMember, form, hourlyRates]);

  useEffect(() => {
    const fetchData = async () => {
      if (visible && editTabkey === 'addins') {
        const { data } = await apiFetchProjectExtensionsForProject(projectId);
        if (data) {
          setProjectExtensions(data);
        }
      }
    };
    fetchData();
  }, [visible, editTabkey, projectId]);

  useEffect(() => {
    if (projectMember && projectMember.data && visible) {
      setProjectExtensionAccesses(projectMember.data.projectExtensionAccesses);
    }
  }, [projectMember, visible]);

  //#endregion

  //#region ------------------------------ Methods / Handlers

  const handleValuesChange = (changedValues: any, allValues: any) => {
    if (initialValues) {
      const isFormChanged =
        JSON.stringify(initialValues) !== JSON.stringify(allValues);
      setIsChanged(isFormChanged);
    }
  };

  const handleFinish = async (values: initialValues) => {
    setLoading(true);
    const updatedItem = {
      ...projectMember.data,
      jobTitle: values.jobTitle,
      projectRoles: values.roles as ProjectRole[],
      notes: values.notes,
    };
    const rollbackItem = {
      ...projectMember.data,
      jobTitle: projectMember.data.jobTitle,
      projectRoles: projectMember.data.projectRoles,
      notes: projectMember.data.notes,
    } as InternalProjectContact | ExternalProjectContact;

    let updatedItem2: InternalProjectContact | null = null;
    let rollbackItem2: InternalProjectContact | null = null;

    // Check if hourlyRate has changed
    if (
      values.hourlyRate &&
      values.hourlyRate !== projectMember.data.hourlyRateId
    ) {
      updatedItem2 = {
        ...projectMember.data,
        hourlyRateId: values.hourlyRate,
      } as InternalProjectContact;
      rollbackItem2 = {
        ...projectMember.data,
        hourlyRateId: projectMember.data.hourlyRateId,
      } as InternalProjectContact;

      // Dispatch the hourly rate update
      dispatch(
        updateInternalProjectContact(projectId, [updatedItem2], [rollbackItem2])
      );
    }

    // Check if other properties have changed
    if (
      values.jobTitle !== projectMember.data.jobTitle ||
      JSON.stringify(values.roles) !==
        JSON.stringify(projectMember.data.projectRoles) ||
      values.notes !== projectMember.data.notes
    ) {
      // Dispatch the update for other properties
      dispatch(updateProjectContacts(projectId, [updatedItem], [rollbackItem]));
    }

    // This is just a workaround until we implement the optimistic write story
    setTimeout(() => {
      onRefresh();
      setLoading(false);
      setOpenDrawer(false);
    }, 2000);
  };

  const handleArchiveButtonClicked = () => {
    const updatedItem = {
      ...projectMember.data,
      isArchived: true,
    };
    const rollbackItem = {
      ...projectMember.data,
      isArchived: false,
    } as InternalProjectContact | ExternalProjectContact;
    dispatch(updateProjectContacts(projectId, [updatedItem], [rollbackItem]));
    // this is just a workaround until we implement the optimistic write story
    setTimeout(() => {
      onRefresh();
      setOpenDrawer(false);
    }, 2000);
  };

  //#endregion

  //#region ------------------------------ Tabs

  const renderGeneralTabContent = (): ReactNode => {
    return (
      <Form
        initialValues={initialValues}
        form={form}
        onValuesChange={handleValuesChange}
        onFinish={handleFinish}
        layout="vertical"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <div>
          <Form.Item
            name="jobTitle"
            label={t('contacts:projectContactsPage.columns.jobTitle')}
          >
            <JobTitleSelect
              disabled={
                !myProjectRoles.includes('projectAdmin') &&
                !myProjectRoles.includes('projectAssistance') 
              }
            />
          </Form.Item>
          {!projectMember?.data?.externalProjectContactId && (
            <>
              <Form.Item
                name="hourlyRate"
                label={t('contacts:projectContactsPage.columns.hourlyRate')}
                style={{
                  display:
                    myProjectRoles.includes('projectAdmin') ||
                    myProjectRoles.includes('projectController')
                      ? 'flex'
                      : 'none',
                }}
              >
                <Select showSearch={false} options={hourlyRateOptions}></Select>
              </Form.Item>
              <Form.Item
                name="roles"
                label={t('contacts:projectContactsPage.columns.projectRoles')}
              >
                <Select
                  showSearch={false}
                  options={roleOptions}
                  mode="tags"
                  disabled={
                    !myProjectRoles.includes('projectAdmin') &&
                    !myProjectRoles.includes('projectAssistance')
                  }
                ></Select>
              </Form.Item>
            </>
          )}
          {projectMember?.data?.externalProjectContactId && (
            <Form.Item
              name="notes"
              label={t('contacts:projectContactsPage.columns.notes')}
            >
              <TextArea
                placeholder={t('contacts:projectContactsPage.noNotes')}
              ></TextArea>
            </Form.Item>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="link"
            style={{ color: 'red' }}
            onClick={handleArchiveButtonClicked}
          >
            {t('contacts:projectContactsPage.actions.archive')}
          </Button>
          <Form.Item style={{ marginBottom: '0px' }}>
            <Button type="default" onClick={() => setOpenDrawer(false)}>
              {t('common:cancel')}
            </Button>

            <Button
              htmlType="submit"
              disabled={!isChanged || loading}
              style={{ marginInline: '8px' }}
              loading={loading}
            >
              {t('common:save')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    );
  };

  const renderAddInsTabContent = (): ReactNode => {
    return (
      <div>
        {projectExtensions.map((extension, index) => (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SvgIcon
                  key={index}
                  width={40}
                  height={40}
                  path={getSvgIconPathByType(extension.projectExtensionType)}
                />
                <div key={index}>
                  {extension.projectExtensionType
                    .charAt(0)
                    .toLocaleUpperCase() +
                    extension.projectExtensionType.slice(1)}
                </div>
              </div>
              <Switch
                checked={projectExtensionAccesses.some(
                  (ext) =>
                    ext.projectExtensionId === extension.projectExtensionId &&
                    ext.shouldHaveAccess === true
                )}
                onChange={async (checked) => {
                  const updatedAccess = {
                    projectExtensionId: extension.projectExtensionId,
                    shouldHaveAccess: checked,
                    contactId: projectMember.data.contactId,
                  };

                  try {
                    const { data } = await apiUpdateProjectExtensionAccesses(
                      projectId,
                      [updatedAccess]
                    );
                    if (data) {
                      setProjectExtensionAccesses((prevAccesses) =>
                        prevAccesses.map((ext) =>
                          ext.projectExtensionId ===
                          extension.projectExtensionId
                            ? { ...ext, shouldHaveAccess: checked }
                            : ext
                        )
                      );
                      // this is just a workaround until we implement the optimistic write story
                      setTimeout(() => {
                        onRefresh();
                      }, 2000);
                    }
                  } catch (error) {
                    console.error('Failed to update extension access:', error);
                  }
                }}
              ></Switch>
            </div>
            {}
            <Divider type="horizontal" />
          </>
        ))}
      </div>
    );
  };

  const tabs = [
    {
      key: 'general',
      label: t('contacts:projectContactsPage.drawerTabs.general'),
      content: renderGeneralTabContent(),
    },
  ];

  if (
    myProjectRoles.includes('projectAdmin') &&
    projectMember?.data?.externalProjectContactId === null
  ) {
    tabs.push({
      key: 'addins',
      label: t('contacts:projectContactsPage.drawerTabs.addIns'),
      content: renderAddInsTabContent(),
    });
  }

  //#endregion

  return (
    <Drawer
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <UserAvatar
            contact={contactsById[projectMember?.data?.contactId]}
            backgroundColor="#1a2f41"
            size="medium"
          ></UserAvatar>
          <div style={{ marginLeft: '8px' }}>
            <div
              style={{
                fontSize: '18px',
                fontWeight: '600',
                marginBottom: '2px',
              }}
            >
              {contactsById[projectMember?.data?.contactId]?.firstName +
                ' ' +
                contactsById[projectMember?.data?.contactId]?.lastName}
            </div>
            <div style={{ fontSize: '12px', fontWeight: '400' }}>
              {
                getCompanyById(
                  contactsById[projectMember?.data?.contactId]?.companyId
                )?.fullName
              }
            </div>
          </div>
        </div>
      }
      visible={visible}
      tabs={tabs}
      onClose={() => {
        setOpenDrawer(false);
        setInitialValues(null);
      }}
      closable
      defaultTab={
        projectMember?.data?.externalProjectContactId === null
          ? editTabkey
          : 'general'
      }
      onTabChange={(key) => setEditTabKey(key)}
    ></Drawer>
  );
};
export default ProjectContactsDrawer;
