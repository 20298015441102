import React, { useRef, useState } from 'react';
import NavigationBar from '../../../components/NavigationBar';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { TimeRecord, TimeRecordsFilter } from '../../../models/TimeRecord';
import { Button, Drawer } from '@prio365/prio365-react-library';
import {
  DocumentTemplateFormWrapper,
  DocumentTemplateFormWrapperRef,
} from '../../documents/components/DocumentTemplateFrom/DocumentTemplateFormWrapper';
import { ProjectId } from '../../../models/Types';
import { useDispatch } from 'react-redux';
import { openTimeAndLeaveManagementDrawer } from '../../timeAndLeaveManagement/actions';
import TimeRecordsPage from './TimeRecordsPage';
import FilterContextProvider from '../../../components/Filter/FilterContextProvider';
import DocumentTemplateCategoryContextProvider from '../../documents/components/DocumentTemplateCategoryContextProvider';
import DocumentTemplateTagContextProvider from '../../documents/components/DocumentTemplateTagContextProvider';
import { FilterBar } from '../../../components/Filter/FilterBar';
import moment from 'moment';

const useStyles = makePrioStyles((theme) => ({
  root: {
    flex: 1,
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    width: '100%',
    overflow: 'hidden',
  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface TimeRecordControllingSubModuleProps {
  projectId: ProjectId;
}

export const TimeRecordControllingSubModule: React.FC<
  TimeRecordControllingSubModuleProps
> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { projectId } = props;
  const { t } = useTranslation();
  const formWrapperRef = useRef<DocumentTemplateFormWrapperRef>(null);
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------- States / Attributes / Selectors
  const [allTimeRecords, setAllTimeRecords] = useState<TimeRecord[]>([]);

  const [reportDrawerVisible, setReportDrawerVisible] = useState(false);
  const [selectedTimeRecords, setSelectedTimeRecords] = useState<TimeRecord[]>(
    []
  );
  const [isFormValid, setIsFormValid] = useState(false);
  const [dateRange, setDateRange] = useState<TimeRecordsFilter>({
    from: null,
    to: null,
  });
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onCloseReport = () => {
    setReportDrawerVisible(false);
  };

  const onCreateDocumentSuccess = () => {
    setReportDrawerVisible(false);
  };
  const onStartCreateDocument = () => {
    setReportDrawerVisible(false);
  };

  const onCancelReportDrawer = () => {
    setReportDrawerVisible(false);
  };

  const showTimeRecordDrawer = () =>
    dispatch(openTimeAndLeaveManagementDrawer({ tab: 'timeRecords' }));

  const handleSearchStringChange = (searchString: string) => {
    if (searchString !== null) {
      const fromMatch = searchString?.match(/Data\.Day\sge\s'([^']+)'/);
      const toMatch = searchString?.match(/Data\.Day\sle\s'([^']+)'/);

      const relativeMatch = searchString?.match(/Data\.Day\srel\s'([^']+)'/);

      let newDateRange: TimeRecordsFilter = {
        from: null,
        to: null,
      };

      if (fromMatch || toMatch) {
        newDateRange = {
          from: fromMatch ? moment.utc(fromMatch[1]) : null,
          to: toMatch ? moment.utc(toMatch[1]).endOf('day') : null,
        };
      } else if (relativeMatch) {
        const relativeValue = relativeMatch[1];

        switch (relativeValue) {
          case 'tm':
            newDateRange.from = moment().utc().startOf('month');
            newDateRange.to = moment().utc().endOf('month');
            break;
          case 'ty':
            newDateRange.from = moment().utc().startOf('year');
            newDateRange.to = moment().utc().endOf('year');
            break;
          case 'tw':
            newDateRange.from = moment().utc().startOf('isoWeek');
            newDateRange.to = moment().utc().endOf('isoWeek');
            break;
          case 'l6m':
            newDateRange.from = moment()
              .utc()
              .subtract(6, 'months')
              .startOf('month');
            newDateRange.to = moment()
              .utc()
              .subtract(1, 'months')
              .endOf('month');
            break;
          case 'lq':
            newDateRange.from = moment()
              .utc()
              .subtract(1, 'quarter')
              .startOf('quarter');
            newDateRange.to = moment()
              .utc()
              .subtract(1, 'quarter')
              .endOf('quarter');
            break;
          case 'ly':
            newDateRange.from = moment()
              .utc()
              .subtract(1, 'year')
              .startOf('year');
            newDateRange.to = moment().utc().subtract(1, 'year').endOf('year');
            break;
          case 'l365d':
            newDateRange.from = moment()
              .utc()
              .subtract(365, 'days')
              .startOf('day');
            newDateRange.to = moment().utc().endOf('day');
            break;
          default:
            break;
        }
      } else {
        newDateRange = {
          from: moment.utc('1900-01-01T00:00:00.000Z'),
          to: moment.utc('2200-12-31T23:59:59.999Z'),
        };
      }

      if (
        (newDateRange?.from && !newDateRange.from.isSame(dateRange?.from)) ||
        (newDateRange?.to && !newDateRange.to.isSame(dateRange?.to))
      ) {
        setDateRange(newDateRange);
      }
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  if (!projectId) return null;

  return (
    <div className={classes.root}>
      <NavigationBar>
        <Button onClick={showTimeRecordDrawer} iconProp={['fal', 'pencil']}>
          <span>{t('common:navigationBar.recordTime')}</span>
        </Button>
        <Button
          disabled={!(allTimeRecords?.length > 0)}
          onClick={() => setReportDrawerVisible(true)}
          iconProp={['fal', 'file-excel']}
          type="default"
        >
          <span>{t('timeRecords:navigationBar.createReport')}</span>
        </Button>
      </NavigationBar>
      <TimeRecordsPage
        className={classes.content}
        setTimeRecords={setAllTimeRecords}
        setSelectedTimeRecordsObjects={setSelectedTimeRecords}
        contextType="project"
        setReportDrawerVisible={setReportDrawerVisible}
        setSearchString={handleSearchStringChange}
      />
      <Drawer
        title={t('timeRecords:navigationBar.createReport')}
        closable={true}
        onClose={onCloseReport}
        visible={reportDrawerVisible}
        customWidth={800}
        contentClassName={classes.drawerContent}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button type="default" onClick={onCloseReport}>
              {t('common:actions.cancel')}
            </Button>
            <Button
              onClick={() => formWrapperRef.current?.onFinish()}
              disabled={!isFormValid}
              style={{
                marginLeft: '12px',
              }}
            >
              {t('common:actions.save')}
            </Button>
          </div>
        }
      >
        <DocumentTemplateTagContextProvider>
          <DocumentTemplateCategoryContextProvider>
            <FilterContextProvider
              searchType="documentTemplates"
              customDefaultSearchParameters={[
                {
                  parameterName: 'Data.IsReport',
                  defaultValue: 'true',
                  defaultMethod: 'eq',
                },
              ]}
              disableURLQuery={true}
            >
              <FilterBar hiddenPickers={['Data.IsReport']} />
              <DocumentTemplateFormWrapper
                showOnlyReports={true}
                timeRecords={
                  selectedTimeRecords.length > 0
                    ? selectedTimeRecords
                    : allTimeRecords
                }
                filter={dateRange}
                onSuccess={onCreateDocumentSuccess}
                onStartCreateDocument={onStartCreateDocument}
                onCancel={onCancelReportDrawer}
                ref={formWrapperRef}
                setIsFormValid={setIsFormValid}
                projectPickerFilter={({
                  isArchived,
                  projectId: _projectId,
                  parentProject,
                }) => {
                  return (
                    !isArchived &&
                    (_projectId === projectId || parentProject === projectId)
                  );
                }}
              />
            </FilterContextProvider>
          </DocumentTemplateCategoryContextProvider>
        </DocumentTemplateTagContextProvider>
      </Drawer>
    </div>
  );
};
export default TimeRecordControllingSubModule;
